import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  responsiveFontSizes,
} from '@mui/material/styles';
import { palette, background } from 'utils/theme';

export const ThemeProvider = ({ children, theme }) => {
  const mode = 'light';
  let themeConfig = {
    ...theme,
    palette:
      mode === 'dark' ? { ...palette, mode } : { ...palette, background, mode },
  };
  themeConfig = createTheme(themeConfig);
  themeConfig = responsiveFontSizes(themeConfig);

  return <MuiThemeProvider theme={themeConfig}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
