export const FALLBACK_IMAGE = 'https://bit.ly/broken-link';
export const LOGO = '/logo.svg';
export const EMPTY_DASHBOARD_ICON = '/empty-dashboard.jpeg';
export const PROFILE_BANNER_IMAGE = '/banner-pattern.jpeg';
export const DEFAULT_PROFILE_IMAGE = '/user-default-icon.png';
export const DEFAULT_COMPANY_IMAGE = '/company-logo.png';
export const DEFAULT_UNIVERSITY_IMAGE = '/company-logo.png';
export const UPLOAD_IMAGE = '/upload-icon.svg';
export const JOB_DETAILS_BANNER = '/Rectangle 548.png';
export const COMPANY_DETAILS_BANNER = '/Rectangle 548 (1).png';
