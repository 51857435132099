import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { createContext, useContext, useEffect, useState } from 'react';
import { noop } from 'utils';
import { DRAWER_WIDTH, DRAWER_WIDTH_MINI } from 'utils/constants/app';

const AppContext = createContext({
  open: true,
  mini: false,
  drawerWidth: DRAWER_WIDTH,
  toggleOpen: noop,
  toggleMini: noop,
});

export const useApp = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const [open, setOpen] = useState(true);
  const [mini, setMini] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState(DRAWER_WIDTH);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (mobile) {
      setOpen(false);
      setMini(false);
    } else {
      setOpen(true);
    }
  }, [mobile]);

  useEffect(() => {
    if (open && mini) setDrawerWidth(DRAWER_WIDTH_MINI);
    else if (open && !mini) setDrawerWidth(DRAWER_WIDTH);
    else setDrawerWidth(0);
  }, [open, mini]);

  const toggleOpen = () => {
    setOpen(val => !val);
  };

  const toggleMini = () => {
    setMini(val => !val);
  };

  const contextValue = {
    open,
    mini,
    mobile,
    drawerWidth,
    toggleOpen,
    toggleMini,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export default AppProvider;
