export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const AUTH_API_ENDPOINT = process.env.REACT_APP_AUTH_API_ENDPOINT;
export const YOUNG_BRAIN_API_ENDPOINT =
  process.env.REACT_APP_YOUNG_BRAIN_API_ENDPOINT;
export const baseEndpoint = api => API_ENDPOINT + api;
export const authBaseEndpoint = api => AUTH_API_ENDPOINT + api;
export const youngbrainBaseEndpoint = api => YOUNG_BRAIN_API_ENDPOINT + api;

// Subcriptions Endpoints
export const getAllSubscriptionPlansApi = () =>
  baseEndpoint(
    '/subscriptionplan/getAll?page=0&count=10&sort=ASC&field=subscriptionplanid'
  );

export const createSubscriptionPlanApi = () =>
  baseEndpoint('/subscriptionplan/createsubscriptionplan');

export const getAllApplicationsApi = () =>
  baseEndpoint('/subscriptionplan/getallapplications');

export const jobsApi = () => baseEndpoint('/companyData/jobs');

// Auth Endpoints
export const loginUserApi = () => authBaseEndpoint('/auth/signin');

export const jobAnalysisApi = () => baseEndpoint('/job/all');

// Jobs Endpoints

export const createJobApi = (params = '') =>
  baseEndpoint('/job/create' + params);

export const editJobApi = (id = '') => baseEndpoint('/job/edit/' + id);

export const deleteJobApi = (id = '') => baseEndpoint('/job/edit/' + id);

export const getJobsApi = (params = '') => baseEndpoint('/job/all' + params);

export const getJobApplicationsApi = (params = '') =>
  baseEndpoint('/jobApplications/all' + params);

export const getJobInterviewsApi = (params = '') =>
  baseEndpoint('/interviews/all' + params);

export const sendInterviewInviteApi = (params = '') =>
  baseEndpoint('/job/interview-invite' + params);

export const updateInterviewInviteApi = (params = '') =>
  baseEndpoint('/job/interview-update/' + params);

export const rescheduleInterviewInviteApi = (params = '') =>
  baseEndpoint('/jobs/interview-invites' + params);

export const rejectJobApplicationApi = (params = '') =>
  baseEndpoint('/job/interview-application/' + params);

export const getJobOfferLettersApi = (params = '') =>
  baseEndpoint('/job-offers/all' + params);

export const sendOfferLetterApi = (params = '') =>
  baseEndpoint('/job/offer' + params);

export const getCoursesForJobApi = (params = '') =>
  baseEndpoint('/jobs/interview-invites' + params);

export const saveCoursesForJobApi = (params = '') =>
  baseEndpoint('/course/assign' + params);

export const getJobLocationsApi = () => baseEndpoint('/job-locations');

// Seminars API
export const createSeminarApi = (params = '') =>
  baseEndpoint('/seminar/' + params);

export const editSeminarApi = (id = '') =>
  baseEndpoint('/seminar/update/' + id);

export const deleteSeminarApi = (params = '') =>
  baseEndpoint('/seminar/delete' + params);

export const getSavedSeminarsApi = (params = '') =>
  baseEndpoint('/seminar/all' + params);

export const getPublishedSeminarsApi = (params = '') =>
  baseEndpoint('/seminar/all' + params);

export const getClosedSeminarsApi = (params = '') =>
  baseEndpoint('/seminar/all' + params);

export const publishSeminarPostingApi = (params = '') =>
  baseEndpoint('/seminar/publish' + params);

export const getRegisteredSeminarApplicationsApi = (params = '') =>
  baseEndpoint('/seminar/applications/all' + params);

export const getAttendenceSeminarApplicationsApi = (params = '') =>
  baseEndpoint('/seminar/applications/all' + params);

export const updateAttendenceSeminarApplicationsApi = (id = '') =>
  baseEndpoint('/seminar-application/update/' + id);

export const publishSavedSeminarApi = (params = '') =>
  baseEndpoint('/seminar/publish' + params);

export const updateSeminarApi = (id = '') =>
  baseEndpoint('/seminar/update/' + id);

export const getSeminarQuestions = (id = '') =>
  baseEndpoint('/seminar/questions/' + id);

// Internship API
export const createInternshipApi = (params = '') =>
  baseEndpoint('/internship/' + params);

export const editInternshipApi = (id = '') =>
  baseEndpoint('/internship/update/' + id);

export const deleteInternshipApi = (params = '') =>
  baseEndpoint('/internship/delete' + params);

export const getSavedInternshipsApi = (params = '') =>
  baseEndpoint('/internship/all' + params);

export const getPublishedInternshipsApi = (params = '') =>
  baseEndpoint('/internship/all' + params);

export const getClosedInternshipsApi = (params = '') =>
  baseEndpoint('/internship/all' + params);

export const publishInternshipPostingApi = (params = '') =>
  baseEndpoint('/internship/publish' + params);

export const getRegisteredInternshipApplicationsApi = (params = '') =>
  baseEndpoint('/internship/applications/all' + params);

export const getAttendenceInternshipApplicationsApi = (params = '') =>
  baseEndpoint('/internship/applications/all' + params);

export const updateAttendenceInternshipApplicationsApi = (id = '') =>
  baseEndpoint('/internship-application/update/' + id);

export const publishSavedInternshipApi = (params = '') =>
  baseEndpoint('/internship/publish' + params);

export const updateInternshipApi = (id = '') =>
  baseEndpoint('/internship/update/' + id);

//Internship Request Api
export const editInternshiRequestpApi = (id = '') =>
  baseEndpoint('/internship/update/' + id);

export const getSelectedCandidateApi = (params = '') =>
  baseEndpoint('/internship/selected' + params);

export const sendInternshipOfferLetterApi = (id = '') =>
  baseEndpoint('/internship/offer' + id);

// Manage University Api
export const sendGrantApi = (id = '') =>
  baseEndpoint('/scholarship/grant' + id);

export const viewDetailsApi = (params = '') =>
  baseEndpoint('/recommendations/all' + params);

export const denyApi = (id = '') => baseEndpoint('/scholarship/deny' + id);

export const denyRecommendationApi = (id = '') =>
  baseEndpoint('/recommendation/deny' + id);

export const denyInternshipApi = (id = '') =>
  baseEndpoint('/request-internship/deny' + id);

export const denyJobForUniApi = (id = '') =>
  baseEndpoint('/jobForUniversity/deny' + id);

export const denySeminarApi = (id = '') =>
  baseEndpoint('/request-seminar/deny' + id);

export const holdApi = (id = '') => baseEndpoint('/recommendation/hold' + id);

export const holdInternshipApi = (id = '') =>
  baseEndpoint('/request-internship/hold' + id);

export const holdJobForUniApi = (id = '') =>
  baseEndpoint('/jobForUni/hold' + id);

export const holdRecommendationApi = (id = '') =>
  baseEndpoint('/request-seminar-recommendation/hold' + id);

export const acceptInternshipApi = (id = '') =>
  baseEndpoint('/request-internship/accept' + id);

export const acceptJobForUniApi = (id = '') =>
  baseEndpoint('/jobForUni/accept' + id);

export const acceptSeminarApi = (id = '') =>
  baseEndpoint('/request-seminar-recommendation/accept' + id);

export const sendGrantRecieptApi = (id = '') =>
  baseEndpoint('/scholarship/reciept-grant' + id);

export const editSeminarRequestpApi = (id = '') =>
  baseEndpoint('/seminar-recommend/update/' + id);
export const editJobRequestpApi = (id = '') =>
  baseEndpoint('/jobForUni/update/' + id);

export const sendJobForUniOfferLetterApi = (id = '') =>
  baseEndpoint('/jobForUni/offerLetter' + id);

export const sendJobForUniInvitationApi = (id = '') =>
  baseEndpoint('/jobForUni/invitation' + id);
export const sendJobInterviewInviteApi = (params = '') =>
  baseEndpoint('/jobForUni/interview-invite' + params);

// Handshake Api
export const sendHandshakeRequestApi = (id = '') =>
  baseEndpoint('/handshake/request' + id);
export const ShareDocumentApi = (params = '') =>
  baseEndpoint('/handshake/shareDocument' + params);

// Request Donation
export const sendDonationGrantRecieptApi = (id = '') =>
  baseEndpoint('/requestDonation/reciept-grant' + id);

export const denyDonationApi = (id = '') =>
  baseEndpoint('/requestDonation/deny' + id);

export const HoldDonationApi = (id = '') =>
  baseEndpoint('/requestDonation/hold' + id);

export const getManagedSavedSeminarsApi = (params = '') =>
  baseEndpoint('/seminar/all' + params);

// profile
export const getStudentProfileApi = user =>
  authBaseEndpoint(`/students/${user}`);
export const editStudentProfileApi = user =>
  youngbrainBaseEndpoint(`/students/edit-profile/${user}`);
export const getCertificateIssuingOrgListApi = () =>
  youngbrainBaseEndpoint('/certificate-organization-list');
export const getSchoolUniversityListApi = () =>
  youngbrainBaseEndpoint('/school-university-list');
export const getFacultyDepartmentListApi = () =>
  youngbrainBaseEndpoint('/faculty-department-list');
export const getCertificationListApi = () =>
  youngbrainBaseEndpoint('/faculty-department-list');
