export const HOME = '/app';
export const DASHBOARD_HOME = '/app/dashboard';
export const DASHBOARD_SEMINAR = '/app/dashboard/seminar-analysis';
export const DASHBOARD_JOB = '/app/dashboard/job-analysis';

// Job
export const JOB_HOME = '/app/job';
export const PUBLISHED_JOBS = '/app/job?tab=1';
export const CREATE_JOB = '/app/job/create-job';
export const EDIT_JOB = '/app/job/edit-job';
export const JOB_APPLICATIONS = '/app/job/applications';
export const jobApplications = jobId => `/app/job/applications/${jobId}`;
export const jobApplicationsOfferLetter = jobId =>
  `/app/job/applications/${jobId}?tab=3`;
export const ASSIGN_COURSES =
  '/app/job/:jobId/applications/:applicationId/assign-courses';

export const assignCourse = (jobId, applicationId) =>
  `/app/job/${jobId}/applications/${applicationId}/assign-courses`;
export const JOB_REMINDER = '/app/job/send-reminders';
export const JOB_PUBLISH_PAGE = '/app/job/publish';

// Dashboard
export const DASHBOARD = `/app/dashboard`;

// Jobs
export const searchJobs = () => `/app/jobs/search`;
export const appliedJobs = () => `/app/jobs/applied`;
export const offeredJobs = () => `/app/jobs/offered`;
export const jobDetails = (id = ':id') => `/app/jobs/${id}/details`;

// Interviews
export const manageInterviews = () => `/app/interviews/manage`;

// Profile
export const profile = () => `/app/profile`;
export const profileDetails = () => `/app/profile-deatails`;

// Seminar
export const SEMINAR_HOME = '/app/seminar';
export const CREATE_SEMINAR = '/app/seminar/create-seminar';
export const SEMINAR_PUBLISH_PAGE = '/app/seminar/publish';
export const EDIT_SEMINAR = '/app/seminar/edit-seminar';
export const SEMINAR_REGISTRATIONS = '/app/seminar/registrations';
export const PUBLISHED_SEMINARS = '/app/seminar?tab=1';
export const seminarQuestionaries = (seminarId = ':seminarId') =>
  `/app/seminar/${seminarId}/questionaries`;

// Internship
export const INTERNSHIP_HOME = '/app/internships';
export const CREATE_INTERNSHIP = '/app/internships/create-internship';
export const INTERNSHIP_PUBLISH_PAGE = '/app/internships/publish';
export const EDIT_INTERNSHIP = '/app/internships/edit';
export const INTERNSHIP_REGISTRATIONS = '/app/internships/registrations';
export const PUBLISHED_INTERNSHIPS = '/app/internships?tab=1';

// Internship Request
export const INTERNSHIP_REQUEST_HOME = '/app/internship-request';

// Internship Contract
export const INTERNSHIP_CONTRACT_HOME = '/app/internship-contract';

//Manage University
export const MANAGE_UNIVERSITIES_HOME = '/app/manage-university';
export const REQUESTS_RECOMMENDATIONS_HOME =
  '/app/university/requests-recommentdations';
export const REQUEST_ACCEPTED_INTERNSHIP_HOME =
  '/app/university/internship/accepted';
export const REQUESTS_Internship_HOME = '/app/university/internships/requests';
export const REQUEST_SEMINAR_HOME = '/app/university/seminars';
export const JOB_FOR_UNIVERSITY_HOME = '/app/university/jobs';
export const HANDSHAKE_HOME = '/app/handshake';
export const HANDSHAKE_NDA_UPLOAD = (id = ':id') =>
  `/app/handshake/nda/${id}/upload`;
export const HANDSHAKE_NDA_UPLOADED = (id = ':id') =>
  `/app/handshake/nda/${id}/uploaded`;
export const SEMINAR_RECOMMEND_REGISTRATIONS =
  '/app/university/seminars/recommended-registrations';

export const JOBFORUNI_RECOMMEND_REGISTRATIONS =
  '/app/university/jobs/recommended-registrations';

export const JOBFORUNI_ASSIGN_COURSE = '/app/university/jobs/assignCourse';
export const REQUEST_DONATION_HOME = '/app/requestDonation';
export const HANDSHAKE_DOCUMENT_SHARE =
  '/app/handshake/non-discloser/shareDocument';
export const universitySeminarQuestionaries = (
  universityId = ':universityId'
) => `/app/university/seminars/${universityId}/questionaries`;

// Course
export const COURSE_HOME = '/app/course';
export const PROFILE = '/app/profile';

// Notification
export const NOTIFICATION_HOME = '/app/notification';

// Help and Feedback
export const HELP_AND_FEEDBACK = '/app/help-and-feedback';

// About
export const ABOUT_HOME = '/app/about';

// Settings
export const SETTINGS_HOME = '/app/settings';

// Auth
export const SIGNOUT = '/app/signout';
export const REGISTER = '/app/register';
export const CREATE_PASSWORD = '/app/account-password';
export const LOGIN = '/app/login';
export const FORGOT_PASSWORD = '/app/forgot-password';
export const RESET_PASSWORD = '/app/reset-password';
export const APP = '/app';
export const CREATE_PROFILE = '/app/create-profile';

// profile
export const STUDENT_RESUME = '/student/profile/resume';
export const EDIT_PROFILE = '/app/profile/edit';

// job details
export const DETAILED_JOB = `/app/jobs/details`;
export const DETAILED_COMPANY = `/app/company/details`;
export const APPLY_DIALOG = `/app/company/dialog`;
